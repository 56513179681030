<template>
    <section class="flex flex-column m-auto align-content-center mt-6">
        <!-- <p>Pagamento com PIX</p> -->
        <div class="field col-12 justify-content-center">
            <div class="flex align-items-center justify-content-center">
                <img v-if="pixDados" height="300" :src="`data:image/PNG;base64,${pixDados?.imagemQRCode}`" alt="QrCode" />
            </div>
            <div>
                <div class="p-fluid flex align-items-center justify-content-center mb-2">
                    <Tag v-if="pixDados" class="flex align-items-center justify-content-center p-2">PIX valido por 24 Horas</Tag>
                </div>

                <p>Pagar com PIX é fácil e rápido</p>
                <ul>
                    <li>1- Aponte a câmera para o QR code</li>
                    <li>2- Clique em pagar PIX no seu aplicativo</li>
                    <li>3- Pronto agora é só finalizar o pagamento e aguardar a aprovação</li>
                </ul>
            </div>
        </div>
        <Button @click="gerarPix" class="m-auto w-full border-1 p-3 flex align-items-center justify-content-center" v-if="pixDados === null">
            <i class="pi pi-qrcode mr-2" />
            Gerar QrCode
        </Button>
        <Button
            v-if="pixDados !== null"
            @click="copiarPix"
            v-tooltip.focus.bottom="{ value: 'Pix Copiado!' }"
            class="m-auto w-full border-1 bg-white p-3 flex align-items-center justify-content-center text-black-alpha-90"
        >
            <i class="pi pi-clone mr-2" />
            Código PIX - Copie e Cole
        </Button>
        <Button
            v-if="pixDados !== null"
            @click="$emit('atualizarPagamento')"
            class="m-auto mt-2 w-full border-1 p-3 flex align-items-center justify-content-center"
        >
            <i class="pi pi-dollar mr-2" />
            Confirmar Pagamento
        </Button>
        <!-- <Button>PIX copia e cola</Button> -->
    </section>
</template>

<script>
import service from './service';

export default {
    name: 'PixForm',

    props: {
        pagamento: null,
    },

    emits: ['atualizarPagamento'],

    data() {
        return {
            pixDados: null,
        };
    },
    methods: {
        gerarPix() {
            this.$store.dispatch('addRequest');
            service
                .pagamentoPix(this.pagamento.produtoCheckoutId, this.pagamento.cpf)
                .then((response) => {
                    if (response.data?.identificador) {
                        this.pixDados = response.data;
                    } else {
                        this.pixDados = null;
                        this.$toast.add({
                            severity: 'error',
                            summary: 'QrCode Pix',
                            detail: `${response.data?.errors[0]?.errorMessage}`,
                            life: 3000,
                        });
                    }
                })
                .catch((error) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'QrCode Pix',
                        detail: `${error}`,
                        life: 3000,
                    });
                })
                .finally(() => this.$store.dispatch('removeRequest'));
        },

        async copiarPix() {
            await navigator.clipboard.writeText(this.pixDados?.textoQRCode);
        },
        // Your methods go here
    },
    mounted() {
        // Code to run when the component is mounted goes here
    },
};
</script>

<style scoped>
/* Your component-specific styles go here */
</style>
