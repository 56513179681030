<template>
    <div class="flex justify-content-center align-items-center" style="background-color: #0772b3; height: 80px">
        <a :href="linkLoja" target="_blank">
            <img src="layout/images/logoSebrae.svg" alt="sebrae-logo" style="width: 110px" />
        </a>
    </div>
    <painel titulo="" :mostrarRefresh="false" class="m-auto mt-4 w-11 md:w-10 pb-6">
        <h3 class="border-solid border-bottom-1 surface-border border-right-none border-left-none border-top-none pb-2">Pagamento de Produto</h3>
        <div class="w-full flex align-items-center py-5 border-300" v-if="erro">
            <Message class="w-full" :key="erro" severity="error">{{ erro }}</Message>
        </div>
        <div class="md:flex">
            <div class="info-pagam">
                <div class="card-produto mb-4">
                    <p class="titulo-produto">{{ titulo }}</p>
                    <p class="descricao-produto">{{ descricao }}</p>
                </div>
                <div class="card-valor flex justify-content-between mb-4">
                    <div>
                        <span class="total">Total:</span>
                    </div>
                    <div class="flex flex-column text-right" style="max-width: 13rem">
                        <div class="mb-2">
                            <span class="valor">{{ valorTotal }}</span>
                        </div>
                        <div>
                            <span class="descr-pagamento">{{ msgValorParcelado }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-orientacoes">
                    <div class="flex">
                        <i class="pi pi-info-circle" style="font-size: 2rem; color: #486277"></i>
                        <p class="orient-adici-titulo ml-2">Orientações adicionais</p>
                    </div>
                    <div>
                        <ul style="padding-left: 20px">
                            <li style="color: #989ca1">
                                Informe-se sobre a nossa
                                <a href="/politicascheckoutpagamentos/Política de Privacidade de Clientes do Sebrae Pernambuco.pdf" target="_blank"
                                    >Política de Privacidade</a
                                >
                                e
                                <a
                                    href="/politicascheckoutpagamentos/POLÍTICA COMERCIAL - com diretriz de preços descontos e cortesias - 2023_27072023.pdf _manifesto_ (2).pdf"
                                    target="_blank"
                                    >Política de Comercialização</a
                                >
                                para saber mais sobre a segurança dos seus dados, regras para cancelamento, desistência da compra e disposições gerais.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="card-modos-pagam" style="min-height: 600px">
                <div class="flex justify-content-between">
                    <div class="flex flex-column align-items-center">
                        <Button class="botao-credito flex flex-column p-button-text" @click="modoPagamentoSelecionado = 'CREDITO'">
                            <i
                                :class="modoPagamentoSelecionado !== 'CREDITO' ? 'pi pi-credit-card pi-icon-cc' : 'pi pi-credit-card selecionado'"
                                style="font-size: 4rem"
                            ></i>
                            <span :class="modoPagamentoSelecionado !== 'CREDITO' ? 'descr-modo-pagam' : 'descr-modo-pagam selecionado'">Crédito</span>
                        </Button>
                    </div>
                    <div class="flex flex-column align-items-center">
                        <Button class="botao-debito flex flex-column p-button-text" @click="modoPagamentoSelecionado = 'DEBITO'">
                            <svg
                                :class="modoPagamentoSelecionado !== 'DEBITO' ? 'icon-svg-debito' : 'icon-svg-debito svg-selecionado'"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                            >
                                <path
                                    d="M64 32C28.7 32 0 60.7 0 96v32H576V96c0-35.3-28.7-64-64-64H64zM576 224H0V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V224zM112 352h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16z"
                                />
                            </svg>
                            <span :class="modoPagamentoSelecionado !== 'DEBITO' ? 'descr-modo-pagam' : 'descr-modo-pagam selecionado'">Débito</span>
                        </Button>
                    </div>
                    <div class="flex flex-column align-items-center">
                        <Button class="botao-pix flex flex-column p-button-text" @click="modoPagamentoSelecionado = 'PIX'">
                            <svg
                                :class="modoPagamentoSelecionado !== 'PIX' ? 'icon-svg-pix' : 'icon-svg-pix svg-selecionado'"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.8C231.1-7.6 280.3-7.6 310.6 22.8L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.8L22.8 310.6C-7.6 280.3-7.6 231.1 22.8 200.8L80.8 142.7H112.6z"
                                />
                            </svg>
                            <span :class="modoPagamentoSelecionado !== 'PIX' ? 'descr-modo-pagam' : 'descr-modo-pagam selecionado'">Pix</span>
                        </Button>
                    </div>
                </div>
                <CartaoCreditoForm @erroPagamento="erroPagamento" :parcelas="parcelas" v-if="modoPagamentoSelecionado === 'CREDITO'" />
                <CartaoDebitoForm @erroPagamento="erroPagamento" v-if="modoPagamentoSelecionado === 'DEBITO'" />
                <PixForm @atualizarPagamento="verificarPagamentoPix" v-if="modoPagamentoSelecionado === 'PIX'" :pagamento="pagamento" />
            </div>
        </div>
    </painel>
</template>

<script>
import CartaoCreditoForm from './CartaoCreditoForm.vue';
import CartaoDebitoForm from './CartaoDebitoForm.vue';
import PixForm from './PixForm.vue';
import service from './service';

export default {
    name: 'TelaPagamento',
    components: {
        CartaoCreditoForm,
        CartaoDebitoForm,
        PixForm,
    },
    data() {
        return {
            pagamento: null,
            modoPagamentoSelecionado: 'CREDITO',
            dadosCC: null,

            titulo: null,
            descricao: null,
            valorTotal: null,
            parcelas: [],
            qtdMaxVezes: null,
            qtdValorMaxVezes: null,
            msgValorParcelado: null,

            badeiraCartao: null,
            erro: null,

            linkLoja: process.env.VUE_APP_API_URL_LOJA_CHECKOUT,
        };
    },
    methods: {
        checkoutPagamento() {
            this.$store.dispatch('addRequest');
            service
                .checkoutPagamento(this.$route.params.id)
                .then((response) => {
                    if (response.data.situacao === 2) {
                        return this.exibeEspelhoPedido();
                    }
                    if (response.data.situacao === 3) {
                        return this.linkExpirado();
                    }
                    this.pagamento = response.data;
                    this.preencherPagamento(response.data);
                })
                .catch((error) => {
                    this.erro = error;
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao carregar dados do pagamento', life: 3000 });
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        exibeEspelhoPedido() {
            this.$router.push({
                name: 'espelhoPedido',
                params: {
                    id: this.$route.params.id,
                },
            });
        },
        verificarPagamentoPix() {
            service.checkoutPagamento(this.$route.params.id).then((response) => {
                if (response.data.situacao === 2) {
                    return this.exibeEspelhoPedido();
                } else {
                    this.$toast.add({ severity: 'warn', summary: 'Atenção', detail: 'Pagamento não aprovado', life: 3000 });
                }
            });
        },
        linkExpirado() {
            this.$router.push({
                name: 'linkExpirado',
                params: {
                    id: this.$route.params.id,
                },
            });
        },

        preencherPagamento(checkoutPagamento) {
            this.titulo = checkoutPagamento.produto.titulo;
            this.descricao = checkoutPagamento.produto.descricao;
            this.valorTotal = checkoutPagamento.produto.valorFormatado;
            this.parcelas = checkoutPagamento.parcelas;
            this.quatidadeParcelas();
        },
        // Em até 1x de R$ 0,18 no cartão de crédito
        quatidadeParcelas() {
            this.qtdMaxVezes = this.parcelas.length;
            this.qtdValorMaxVezes = this.parcelas[this.qtdMaxVezes - 1].valorParcelaFormatada;
            this.msgValorParcelado = `Em até  ${this.qtdValorMaxVezes} no cartão de crédito`;
        },

        erroPagamento($event) {
            if (!$event) {
                return (this.erro = null);
            }
            this.erro = $event[0];
        },
    },
    mounted() {
        this.checkoutPagamento();
    },
};
</script>

<style scoped>
::v-deep(.card) {
    background: #f4f4f4;
}

h3 {
    margin-top: 0;
}

.card-produto,
.card-valor,
.card-orientacoes,
.card-modos-pagam {
    background-color: #fff;
    padding: 2rem;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
}

.card-modos-pagam {
    padding-top: 0.5rem;
}

.card-orientacoes {
    background-color: #e7ebec;
}

.card-modos-pagam {
    width: 50%;
    margin-left: 2rem;
}

.titulo-produto {
    color: #2a65b6;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 0;
}

.descricao-produto {
    color: #bec0c0;
    font-size: 1.2rem;
}

.total {
    font-size: 1.8rem;
    font-weight: 700;
}

.valor {
    color: #78b614;
    font-size: 2rem;
    font-weight: 900;
}

.descr-pagamento {
    color: #8795a5;
    font-size: 1rem;
    font-weight: 500;
}

.orient-adici-titulo {
    font-size: 1.5rem;
    color: #486277;
}

::v-deep(.descr-modo-pagam) {
    font-size: 1.2rem;
    color: #afb8c0;
    font-weight: 600;
}

.botao-debito,
.botao-pix {
    color: #afb8c0;
}

.pi-icon-cc {
    color: #afb8c0;
}

.icon-cc {
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 3.5rem;
}

.icon-svg-debito {
    margin-top: 3px;
    margin-bottom: 3px;
    width: 4rem;
    fill: #afb8c0;
}

.icon-svg-pix {
    margin-top: 3px;
    margin-bottom: 4px;
    width: 3.5rem;
    fill: #afb8c0;
}

::v-deep(.p-button) {
    transition: none;
}

.selecionado {
    color: #2a65b6;
}

.svg-selecionado {
    fill: #2a65b6;
}

.botao-credito:hover i,
.botao-credito:hover span,
.botao-debito:hover span,
.botao-pix:hover i,
.botao-pix:hover span {
    color: #2a65b6 !important;
}

.botao-debito:hover svg,
.botao-pix:hover svg {
    fill: #2a65b6 !important;
}

.botao-debito:focus,
.botao-pix:focus,
.botao-credito:focus {
    box-shadow: none !important;
}

@media (max-width: 768px) {
    .card-modos-pagam {
        width: 100%;
        margin: 0 auto;
        margin-top: 40px;
    }
}
</style>
