<template>
    <div class="mt-4">
        <div class="flex flex-column">
            <label class="required">Bandeira do cartão</label>
            <Dropdown
                @blur="v$.bandeiraCCSelecionada.$touch()"
                class="mt-2"
                v-model="bandeiraCCSelecionada"
                :options="bandeirasCD"
                optionLabel="name"
                optionValue="code"
            />
            <small class="p-error" v-if="v$.bandeiraCCSelecionada.$error">Campo obrigatório</small>
        </div>

        <div class="field mt-3">
            <label class="required">Nome do titular (como impresso no cartão)</label>
            <input
                type="text"
                class="input-cc text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                v-model="nomeTitular"
                @keypress="v$.nomeTitular.$touch()"
            />
            <small class="p-error" v-if="v$.nomeTitular.$error">Campo obrigatório</small>
        </div>
        <div class="field mt-2">
            <label class="required">CPF (do titular do cartão)</label>
            <InputMask
                mask="999.999.999-99"
                class="input-cc text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                v-model="cpfCnpjTitular"
                :umask="true"
                @keypress="v$.cpfCnpjTitular.$touch()"
            />
            <small class="p-error mt-2" v-if="v$.cpfCnpjTitular.$error && v$.cpfCnpjTitular.required.$invalid">O CPF é obrigatório!</small>
            <small class="p-error mt-2" v-if="v$.cpfCnpjTitular.validarCPF.$invalid && v$.cpfCnpjTitular.$error && cpfCnpjTitular.length > 0"
                >O CPF informado não é um número válido</small
            >
        </div>
        <div class="field mt-2">
            <label class="required">Número do cartão</label>
            <input
                type="text"
                class="input-cc text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                v-model="numeroCartao"
                @input="aplicarMascaraCC"
                maxlength="19"
                @keypress="v$.numeroCartao.$touch()"
            />
            <small class="p-error" v-if="v$.numeroCartao.$error">Campo obrigatório</small>
        </div>
        <div class="field mt-2">
            <label class="required">Validade</label>
            <div class="flex align-items-start">
                <div class="w-6">
                    <Dropdown class="mt-2 w-full" placeholder="Mês" v-model="mesValidade" :options="meses" @blur="v$.mesValidade.$touch()" />
                    <small class="p-error" v-if="v$.mesValidade.$error">Campo obrigatório</small>
                </div>
                <div class="mt-3" style="margin-left: 1rem; margin-right: 1rem">
                    <p>/</p>
                </div>
                <div class="w-6">
                    <Dropdown class="mt-2 w-full" placeholder="Ano" v-model="anoValidade" :options="anos" @blur="v$.anoValidade.$touch()" />
                    <small class="p-error" v-if="v$.anoValidade.$error">Campo obrigatório</small>
                </div>
            </div>
        </div>
        <div class="grid flex align-items-center">
            <div class="col-6 mt-2">
                <label class="required">Cód de Segurança</label>
                <input
                    type="text"
                    class="input-cc text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full mt-2"
                    v-model="codSeguranca"
                    maxlength="3"
                    style="margin-bottom: 5px"
                    @keypress="v$.codSeguranca.$touch()"
                />
                <small class="p-error" v-if="v$.codSeguranca.$error">Campo obrigatório</small>
            </div>
        </div>
        <Button :disabled="this.v$.$invalid" @click="pagarCartaoDebito" label="Confirmar Pagamento" class="p-button-success w-full mt-2" style="height: 3rem" />
    </div>
</template>

<script>
import service from './service';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
export default {
    name: 'CartaoDebitoForm',
    data() {
        return {
            bandeiraCCSelecionada: null,
            nomeTitular: null,
            cpfCnpjTitular: null,
            numeroCartao: null,
            mesValidade: null,
            anoValidade: null,
            codSeguranca: null,
            parcelaSelecionada: null,
            bandeirasCD: [
                { name: 'Elo', code: 'elo' },
                { name: 'Mastercard', code: 'master' },
                { name: 'Visa', code: 'visa' },
            ],
            meses: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            anos: [],
        };
    },
    emits: ['erroPagamento'],
    methods: {
        exibeEspelhoPedido() {
            this.$router.push({
                name: 'espelhoPedido',
                params: {
                    id: this.$route.params.id,
                },
            });
        },
        popularListaAnos() {
            const anoAtual = new Date().getFullYear();
            const numeroAnos = 15;

            for (let i = 0; i <= numeroAnos; i++) {
                this.anos.push(anoAtual + i);
            }
        },
        aplicarMascaraCC() {
            let value = this.numeroCartao.replace(/\D/g, '');
            if (value.length <= 14) {
                // Máscara para 14 dígitos: xxxx xxxxxx xxxx
                value = value.replace(/(\d{4})(\d{6})(\d{0,4})/, '$1 $2 $3');
            } else if (value.length === 15) {
                // Máscara para 15 dígitos: xxxx xxxxxx xxxxx
                value = value.replace(/(\d{4})(\d{6})(\d{0,5})/, '$1 $2 $3');
            } else if (value.length <= 16) {
                // Máscara para 16 dígitos: xxxx xxxx xxxx xxxx
                value = value.replace(/(\d{4})(\d{4})(\d{4})(\d{0,4})/, '$1 $2 $3 $4');
            }

            this.numeroCartao = value.trim();
        },

        pagarCartaoDebito() {
            this.$emit('erroPagamento');
            this.$store.dispatch('addRequest');
            const cartaoCredito = {
                bandeira: this.bandeiraCCSelecionada,
                nomeTitular: this.nomeTitular,
                cpf: this.cpfCnpjTitular,
                numeroCartao: this.numeroCartao.replaceAll(" ", ""),
                validade: `${this.mesValidade}/${this.anoValidade}`,
                cvv: this.codSeguranca,
                produtoCheckoutId: this.$route.params.id,
            };
            service
                .pagamentoCartaoDebito(cartaoCredito)
                .then((response) => {
                    if (!response.success === true) {
                        return this.$emit('erroPagamento', response.errors);
                    }
                    this.exibeEspelhoPedido();
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
    mounted() {
        this.popularListaAnos();
    },
    setup() {
        return {
            v$: useVuelidate({}),
        };
    },
    validations() {
        return {
            bandeiraCCSelecionada: { required },
            nomeTitular: { required },
            numeroCartao: { required },
            mesValidade: { required },
            anoValidade: { required },
            codSeguranca: { required },
            cpfCnpjTitular: {
                required,
                validarCPF: () => {
                    return this.$validarCPF(this.cpfCnpjTitular.replace(/\D/g, ''));
                },
            },
        };
    },
};
</script>

<style scoped>
.input-cc {
    height: 30.6px;
}
</style>
