import { axiosJwt } from '@/shared/auth/axiosJwt';

const apiBase = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CHECKOUT_PORT}${process.env.VUE_APP_API_PATH}/checkout`;

export default {
    checkoutPagamento(checkoutId) {
        return axiosJwt.get(`${apiBase}/produtocheckout/produto_checkout_pagamento/${checkoutId}`);
    },

    pagamentoCartaoCredito(data) {
        return axiosJwt.post(`${apiBase}/produtos/gerar-pagamento-credito`, data);
    },

    pagamentoCartaoDebito(data) {
        return axiosJwt.post(`${apiBase}/produtos/gerar-pagamento-debito`, data);
    },

    pagamentoPix(produtoCheckoutId) {
        return axiosJwt.get(`${apiBase}/produtos/solicitar-pagamento-pix`, {
            params: {
                produtoCheckoutID: produtoCheckoutId,
            },
        });
    },
};
